<template lang="pug">
	div.page-reports-fraud
		loading(:active.sync="busy", :is-full-page="true")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Fraud Report
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								dw-date-time-picker(v-model="filters.datePicker")
								.form-group
									label Media Source
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.media_source")
								.form-group
									label Network
									select.form-control(v-model="filters.network_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="n in networkOptions", :value="n.id") {{ n.name }}
								.form-group
									label MMP Account
									select.form-control(v-model="filters.mmp_account_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
								.form-group(style="width: 230px;")
									label Asset
									v-select.select-asset(v-model="selectedAsset", :options="assetOptions", label="name")
										template(v-slot:option="option")
											span.select-asset-option
												span.select-asset-icon
													img(:src="option.icon || ''")
												span.select-asset-text
													span.select-asset-name {{ option.name }}
													span.select-asset-package {{ option.package_id }}
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

				p
					button.btn.btn-icon.btn-outline-primary(type="button", @click="toggleReasons()", :class="{'active':viewReasons}")
						i.la.la-columns
				table.table.fraud-report-table.table-responsive
					thead
						tr
							th(rowspan="2") &nbsp;
							th(rowspan="2") Account
							th(rowspan="2") &nbsp;
							th(rowspan="2") Asset
							th.metric(rowspan="2") Blocked Clicks
							th.metric.col-installs(rowspan="2") Installs
							th.metric(rowspan="2") Events
							th.metric.col-rejection(:colspan="1+(viewReasons ?columns.blocked_installs_report.cols.length : 0)") Rejections
							th.metric.col-detection(:colspan="1+(viewReasons ? columns.detection.cols.length : 0)") Post-Attr
							th.metric Block Events
							th.metric Fraud PostAttr Events
						tr
							th.col-total.col-rejection Total
							template(v-if="viewReasons")
								th.col-rejection.col-rejection-sub(v-for="c in columns.blocked_installs_report.cols") {{ c.label }}
							th.col-total.col-detection Total
							template(v-if="viewReasons")
								th.col-detection.col-detection-sub(v-for="c in columns.detection.cols") {{ c.label }}
							th &nbsp;
							th &nbsp;
					template(v-for="ac in result")
						tbody(v-for="a in ac.assets")
							tr.main-record
								td
									button.btn.btn-secondary.btn-sm.btn-expand(type="button", @click="toggleView(a)")
										i.la(:class="{'la-plus':!a.visible, 'la-minus':a.visible}")
								td
									entity(style="min-width: 90px", type="mmp_account", :id="ac.id", :name="ac.name")
								td
									div.app-icon-container
										img(v-if="a.asset.icon", :src="a.asset.icon")
								td [{{ a.asset.id }}] {{ a.asset.name }}
									div
										small.text-muted {{ a.asset.package_id }}
								td {{ a.blocked_clicks_report.total }}
								td.metric.col-installs {{ a.installs_report.total }}
								td.metric {{ a.in_app_events_report.total }}
								td.metric.col-total.col-rejection {{ a.blocked_installs_report.total }}
								template(v-if="viewReasons")
									td.metric.col-rejection.col-rejection-sub(v-for="c in columns.blocked_installs_report.cols") {{ a.blocked_installs_report[c.key] || 0 }}
								td.metric.col-total.col-detection {{ a.detection.total }}
								template(v-if="viewReasons")
									td.metric.col-detection.col-detection-sub(v-for="c in columns.detection.cols") {{ a.detection[c.key] || 0 }}
								td.metric {{ a.blocked_in_app_events_report.total }}
								td.metric {{ a['fraud-post-inapps'].total }}
							template(v-if="a.visible")
								tr.sub-record(v-for="s in a.sources")
									td &nbsp;
									td &nbsp;
									td &nbsp;
									td {{ s.media_source }}
									td {{ s.blocked_clicks_report.total }}
									td.metric.col-installs {{ s.installs_report.total }}
									td.metric {{ s.in_app_events_report.total }}
									td.metric.col-rejection.col-total {{ s.blocked_installs_report.total }}
									template(v-if="viewReasons")
										td.metric.col-rejection.col-rejection-sub(v-for="c in columns.blocked_installs_report.cols") {{ s.blocked_installs_report[c.key] || 0 }}
									td.metric.col-detection.col-total {{ s.detection.total }}
									template(v-if="viewReasons")
										td.metric.col-detection.col-detection-sub(v-for="c in columns.detection.cols") {{ s.detection[c.key] || 0 }}
									td.metric {{ s.blocked_in_app_events_report.total }}
									td.metric {{ s['fraud-post-inapps'].total }}

</template>
<style lang="scss">
.fraud-report-table {
	border: 1px solid #ccc;
	thead {
		tr {
			background: #f0f0ff;

			th {
				border-bottom: 1px solid #ccc;
				vertical-align: middle;
				background: #f0f0ff;
				&.metric {
					text-align: center;
				}
				&.col-rejection-sub,
				&.col-rejection-sub {
					font-weight: normal;
					padding: 1px;
					font-size: 9px;
					&.col-total {
						font-weight: bold;
					}
				}
				&.col-installs {
					background: rgba(20,200,20, 0.1);
				}
			}
		}
	}
	//tbody tr:last-child td {
	//	border-bottom: 1px solid #ccc;
	//}
	.col-rejection {
		background: rgba(255,0,0,.05);
	}
	.col-detection {
		background: rgba(255,200,0,.05);
	}
	td.metric {
		text-align: center;
	}
	.col-total {
		font-weight: bold;
		background: rgba(0,0,0,.05);
	}

	tr.main-record {
		background: rgba(0,0,0,.05);
	}
	tr.sub-record {
		background: rgba(0,0,0,.02);
	}

	td.col-installs {
		background: rgba(20,200,20, 0.1);
	}
}
.btn.btn-sm.btn-expand {
	width: 34px;
	height: 34px;
	padding: 0;
	text-align: center;
	line-height: 34px;
	i {
		padding: 0;
	}
}
</style>
<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment/moment';

async function loadRoute(vm, to, from, next){

	let assetResult = await Vue.$api.get('/assets/getList', {page: 1, limit: 1000});
	let networkOptions = await Vue.$dwData.network.getNetworks();
	let mmpAccountOptions = await Vue.$dwData.mmpAccount.getOptions();
	return vm => {
		vm.assetOptions = assetResult.records;
		vm.networkOptions = networkOptions;
		vm.mmpAccountOptions = mmpAccountOptions;
		// vm.loadData();
		return vm;
	};
}

export default {
	name: 'FraudReport',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data() {
		return {
			busy: false,
			// PERMS: null,
			viewReasons: false,

			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				network_id: null,
				mmp_account_id: null,
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				},
				media_source: '',
			},
			networkOptions: [],
			mmpAccountOptions: [],
			assetOptions: [],
			selectedAsset: null,
			filtersExpanded: false,
			result: [],
			columns: {
				blocked_clicks_report: {cols: []},
				blocked_installs_report: {cols: []},
				detection: {cols: []},
				blocked_in_app_events_report: {cols: []},
				'fraud-post-inapps': {cols: []},
			}
		}

	},
	methods: {
		toggleView(r){
			r.visible = !r.visible;
		},
		toggleReasons(){
			this.viewReasons = !this.viewReasons;
		},
		async loadData() {
			if (this.busy){
				return;
			}
			this.busy = true;
			try {
				let data = {
					timezone: this.filters.datePicker.timezone,
					date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
					date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
					page: this.paginate.page,
					limit: this.paginate.limit,
					media_source: this.filters.media_source,
					mmp_account_id: this.filters.mmp_account_id,
					asset_id: this.selectedAsset ? this.selectedAsset.id : 0
				};

				let resp = await this.$api.post('/fraud-report/query', data);
				this.result = resp.result;
				this.columns = resp.columns;
				for (let m of this.result){
					for (let a of m.assets){
						Vue.set(a, 'visible', false);
					}
				}

				// this.records = resp.records;
				// this.summary = resp.summary;
				// this.paginate.total = resp.total;
				// this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Reports'},
			{title: 'Fraud Report'}
		]);
	}
}
</script>
